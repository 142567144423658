.site-heading {
    margin-bottom: 32px;
    color: $dark-gray;

    @include breakpoint(small only) {
        text-align: center;
    }
}

.homepage-hero-banner {
    position: relative;
}

.homepage-banner-text {
    background-color: #f3681c;
    border-bottom: 1px solid #f9f3b8;
    margin-bottom: 0;
    text-align: center;
    color: #f9f3b8;
    padding: 10px 0;
}

.homepage-carousel {
    li {
        height: 200px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        
        @include breakpoint(medium up) {
            height: 340px;
        }
    }
}

.site-heading-content-container {
    padding-top: 24px;

    @include breakpoint(medium up) {
        // padding-top: 100px;
        padding-bottom: 24px;
    }
}

.site-logo {
    height: 76px;
    width: auto;
}

.site-title {
    font-size: 40px;

    @include breakpoint(medium down) {
        font-size: 28px;
    }
}

.site-tagline {
    font-size: 22px;
    margin-top: 24px;

    @include breakpoint(medium up) {
        margin-top: 0;
        text-align: right;
    }

    @include breakpoint(medium down) {
        font-size: 20px;
    }
}