$font-shadow-into-light: 'Shadows Into Light', cursive;

// Splash specific overrides
.splash {
    // background-color: #DED8D8;
    background: repeat;
    .site-heading {
        color: #827577; //$color-black;
        // background-color: #F4F0F0;
        // border-bottom: 1px solid $color-white;
        // margin-bottom: 0;

        @include breakpoint(medium down) {
            margin-bottom: 0;
        }
    }
    .site-heading-content-container {
        padding-top: 40px;
        padding-bottom: 30px;
        min-height: none;
        padding: 40px 20px 30px;
    }
    .site-logo-container {
        text-align: center;
        padding: 0 20px;

        &:hover {
            .site-logo {
                transform: scale(1.1);
            }
        }
    }
    .site-title {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        line-height: 1;
        margin-bottom: 0;
        margin-top: 20px;
    }
    .site-logo {
        height: 100px;
        transition: transform .3s ease-out;

        @include breakpoint(small down) {
            margin-bottom: 16px;
        }
    }
    .splash-schools-container {
        flex: 1;
        &>.content-row {
            // height: 100%;
        }
    }
    footer {
        text-align: center;
        padding: 10px 0;
        color: $color-white;
        background-color: #484444;
        margin-top: 16px;
        font-size: 12px;
    }
}

.splash-school {
    // padding: 32px;
    text-align: center; // color: $color-white;
    // display: flex;
    // flex-direction: column;
    @include breakpoint(medium down) {
        margin-top: 60px;
        &:first-child {
            margin-top: 0;
        }
    }
    // h2,
    // h4 {
    //     font-family: 'Abhaya Libre', serif;
    // }
    a {
        margin-top: 32px;
        color: $color-primary-3;
        align-self: flex-end;
    }
    .heading-bar {
        padding-bottom: 16px;
    }

    .link-info {
        font-size: 20px;
        font-weight: 700;
        transition: color .3s ease-out;

        &:hover {
            color: lighten($color-primary-3, 10%);
        }
    }
}

// $flip-animation {
//     -ms-transform: rotate(7deg); /* IE 9 */
//     -webkit-transform: rotate(7deg); /* Chrome, Safari, Opera */
//     transform: rotate(7deg);
// }

.school-heading {
    flex: 1;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    display: block;

    .splash-school-1 & {
        background-color: $color-primary-1
    }

    .splash-school-2 & {
        background-color: $color-primary-2
    }

    .splash-school-3 & {
        background-color: $color-primary-3
    }

    img {
        box-shadow: 0px 2px 5px rgba(23, 90, 59, 0.75);
        border: 10px solid $color-white;
        transition: transform .2s ease-out;
    }

    &:hover {
        img {
            -ms-transform: rotate(2deg); /* IE 9 */
            -webkit-transform: rotate(2deg); /* Chrome, Safari, Opera */
            transform: rotate(2deg);
        }
    }
}

.school-call-out {
    font-family: $font-shadow-into-light;
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    color: $color-primary-3;
    font-weight: 600;
    font-size: 30px;
}

.school-title {
    font-size: 25px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;

    .splash-school-1 & {
        color: $color-primary-1
    }

    .splash-school-2 & {
        color: $color-primary-2
    }

    .splash-school-3 & {
        color: $color-primary-3
    }
}

.school-address {
    font-size: 20px;
    font-weight: 500;
    color: #827577;
}

