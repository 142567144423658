.heading-bar {
    color: $color-white;
    padding: 6px 16px;
    margin-bottom: 24px;
}

.content-box-1 {
    .heading-bar {
        background-color: $color-primary-1;
    }

    .sub-heading {
        color: $color-primary-1;
    }
}

.content-box-2 {
    .heading-bar {
        background-color: $color-primary-2;
    }

    .sub-heading {
        color: $color-primary-2;
    }
}

.content-box-3 {
    .heading-bar {
        background-color: $color-primary-3
    }

    .sub-heading {
        color: $color-primary-3;
    }
}

.text-box-shadow {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.75);
}

.sub-heading {
    font-size: 20px;
    color: $color-primary-3;
}