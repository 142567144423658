html, body {
    position: relative;
    height: 100%;
}

.pds-single-page-content {
    .content-row {
        margin-bottom: 32px;
    }
}

.day-highlighted {
    background-color: #b6f1c8;
}


.calendar {
    font-size: $small-font-size;

    @include breakpoint(small down) {
        font-size: 9px;
    }

    .day-character-row {
        @include flex;
        justify-content: space-between;
    }

    .day-details {
        text-align: center;
        margin-top: 20px;
        font-weight: bold;
    }

    th, td {
        border: 1px solid #ddd9d9;
        border-collapse: collapse;

        @include breakpoint(small down) {
            padding: 0.2rem 0.225rem 0.225rem;
        }
    }

    td {
        height: 66px;
        vertical-align: top;

        @include breakpoint(medium) {
            height: 100px;
        }

        &.past-day {
            background-color: rgb(236, 235, 235);
        }
    }



    th {
        width: percentage(0.1428571429);
    }
}

footer {
    text-align: center;
    padding: 10px 0;
    color: $color-white;
    background-color: #484444;
    margin-top: 16px;
    font-size: 12px;
}